
a {
  outline: none;
  text-decoration: none;
}

hr {
  border-top: 1px solid rgba(103, 169, 100, 0.3);
}

.nav-link,
.nav-link:hover {
  color: black;
}

/*
.card-body {
  background-color: white;
  border: none;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  border-color: black;
  border-radius: 10px;
  overflow: hidden;
  width: 600px;
  margin-top: 20px;
}
*/

/*Quotes will be scrollable*/

.windowView {
  background-color: #E5E5E5;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}


.opendemiaButtonContainer > button {
  border-color: transparent;
  margin-left: 0.2rem;
}

.greyButtonContainer > button {
  background-color: #e9ecef;
}

.newGreyButtonContainer > button {
  background-color: var(--opendemia-landing-grey);
}

.whiteButtonContainer > button {
  background-color: white;
}

.opendemiaWhiteButton {
  background-color: white;
}

.widthAutoButton button {
  width: auto !important
}

.btn,
.btn:hover,
.btn-primary,
.btn-primary:hover {
  border-radius: 5px;
 
}

.btn-primary:disabled {
  border-radius: 2px;
  border-color: grey;
}

.btn:focus,
.btn-primary:focus {
  outline: none;
  box-shadow: none;
}

.btn-success {
  background-color: var(--opendemia-green)!important;
  border-color: var(--opendemia-green) !important;
}

.btn-success:hover {
  background-color: var(--opendemia-green) !important;
}

.btn-dark {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
  border-radius: 0;
  width: 100%;
}

.btn-danger {
  background-color: var(--opendemia-landing-grey) !important;
  border-color: var(--opendemia-landing-grey) !important;
}

.btn-img {
  background-color: transparent;
  border-color: transparent;
}

.closed {
  overflow: hidden;
  max-height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
  will-change: transform;
}

.open {
  transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
  will-change: transform;
}

.btn-dark:hover {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
  border-radius: 0;
}

.braintree-form__field-group > label {
  width:100%
}

.braintree-heading {
  color: white;
}

.btn-white {
  background-color: white;
  color: var(--opendemia-green)!important;
}

.btn-white:hover{
  color: white !important;
  background-color: var(--opendemia-green)!important;
  border-color: white;
}

.braintree-toggle {
  margin-bottom: 1rem;
}

.triangle {
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  
  background: var(--opendemia-green);
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.4);
}
.triangle::after {
    content: "";
    position: absolute;
    width: 0;
    margin-left: -1.5em;
    top: -3em;
    left: 50%;
    box-sizing: border-box;
    
    border: 1.5em solid;
    border-color: transparent transparent var(--opendemia-green) transparent;
    
}

.btn-dark-transparent {
  background-color: transparent;
  color: black !important;
}

.content-container { 
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  line-height: 1.5;
  color: rgb(33, 37, 41);
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
}

input.needs-checked {
  border-color: #FBC02D;
}