.mainHeader {
  background-color: #2F4F4F;
  padding: 2%;
  height: 3em;
}

#alertBar {
  font-size: 14px;
  background-color: var(--opendemia-green);
}
.testHeader {
    background-color: var(--opendemia-landing-white-bg);
    /* padding: 20px; */
    /* height: 10px; */
    margin-top: 0px;
    margin-bottom: 0px;
    /* min-height: 60px; */
}
.nav-dropdown-opendemia{
  background-color:var(--opendemia-landing-white-bg);
}

.nav-dropdown-opendemia-inside{
  background-color:var(--opendemia-landing-grey) !important;
}

.dropdown-content a:hover {
  background-color: transparent;
}

.dropdown-item > a{
  color:white
}

.noHoverImportant:hover{
  color:white !important
}

.noHoverImportant{
  /* color:white !important; */
}

td, th {
  border:none !important;
}

.dropdown-item:hover{
  background-color: var(--opendemia-landing-grey) !important;
}

.dropdown-menu .dropdown-item > a:hover {
  background-image: none;
  color: var(--opendemia-green) !important;
}

@media screen and (min-width: 992px) {
  .testHeader {
    height: 3em;
  }
}

#navButton {
  color: black;
  padding-left: .5rem;
  padding-right:.5rem;

}

.pricingButtons{
  color: var(--opendemia-green) !important;
  border-color:  var(--opendemia-green) !important;
  background-color: white !important;
}


.pricingButtons:hover{
  color: var(--opendemia-green) !important;
  border-color:  var(--opendemia-green) !important;
  background-color: white !important;
}
#navButton:hover {
  /* color: var(--opendemia-green) !important; */
  text-decoration: none;
}

.nav-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  top: 0;
}
html,body,.outerDiv {
    height:100%;
}

.outerDiv{
   display:table;
    width: 100%;
    margin-top: -50px;
    padding: 50px 0 0 0; /*set left/right padding according to needs*/
    box-sizing: border-box;
}

.logoClass{
  height: 40px;
}

.shiny
{
  margin-top: 14px;
  color: var(--opendemia-dark-blue);
  background: -webkit-gradient(linear, left top, left bottom, from(#44c8f2), to(#1c294b));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-family: "filson-soft", sans-serif;
  font-size: .57rem;
  font-weight: 900;
  position: relative;
  text-transform: uppercase;
}

/* .shiny::before
{
	background-position: -180px;
	-webkit-animation: flare 5s infinite;
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(65deg, transparent 20%, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.3) 27%, transparent 27%, transparent 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  content: "Pro";
  color: #FFF;
  display: block;
  padding-right: 140px;
  position: absolute;
}

.shiny::after
{
  content: "Pro";
  color: #FFF;
  display: block;
  position: absolute;

  top: 0;
  z-index: -1;
}  */

@-webkit-keyframes flare
{
  0%   { background-position: -180px; }
  30%  { background-position: 500px; }
  100% { background-position: 500px; }
}

@-webkit-keyframes sparkle
{
  0%   { opacity: 0; }
  30%  { opacity: 0; }
  40%  { opacity: 0.8; }
  60%  { opacity: 0; }
  100% { opacity: 0; }
}