@mixin shared 
	padding: 0px
	font-size: 24px
	font-family: Arial, Helvetica, sans-serif
	color: rgb(55, 70, 101)
	// padding-left: 5px
	width: 100%
	clear: both


.mi-textarea 
	@include shared
	margin: -2px -2px 5px 0px
	padding-left: 4px
	border: 2px solid rgb(41, 79, 247)
	border-radius: 4px
	resize: none
	overflow: hidden
	min-height: 30px
	max-height: 500px


.mi-div 
	@include shared
	padding-left: 6px
	padding-bottom: 10px
	margin-bottom: 0px
	overflow-wrap: break-word


.mi-description
	font-size: 16px