:root {
  --main-bg-color: #EFEFEF;
  --main-border: 1px solid black;
  --main-margin: 20px;
  --main-padding: 20px;
  --opendemia-green: #44c8f2;
  /* --opendemia-landing-grey: #38444E; */
  --opendemia-landing-grey:#1c294b;
  --amplify-primary-color: #44c8f2;
  --amplify-primary-shade: #44c8f2;
  --amplify-primary-tint: #1c294b;
  --opendemia-landing-white-bg: white;
  --opendemia-dark-blue: #1c294b;
}