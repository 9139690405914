/*Carosel*/
/* hide disabled buttons */


/* Small devices (landscape phones, 544px and up) */
@media (min-width: 1px) {  
  .titleFontSize {font-size:2.2rem;} 

  .checkboxMobileStyling {
    float:left;
    margin-right:10px;
  }

  .hero-bullet-text{
    text-align: left;
  }

  .styleDiv{
    background-color: var(--opendemia-landing-grey);
    padding-bottom: 0ex;
    /* background-image: url("../Assets/LandingPage/gradholding.png"); */
    background-size: contain, 100%;
    background-position: center center;
    background-repeat: no-repeat;
    padding-bottom: 100px;


  }
}

@media (min-width: 315px) {  

  .styleDiv{
    background-color: var(--opendemia-landing-grey);
    padding-bottom: 0ex;
    /* background-image: url("../Assets/LandingPage/gradholding.png"); */
    background-size: contain, 90%;
    background-position: center 370px;
    background-repeat: no-repeat;
    padding-bottom: 100px;


  }
}

@media (min-width: 376px) {  

  .styleDiv{
    background-color: var(--opendemia-landing-grey);
    padding-bottom: 0ex;
    /* background-image: url("../Assets/LandingPage/gradholding.png") ; */
    background-size: contain, 80%;
    background-position: center 300px;
    background-repeat: no-repeat;
    padding-bottom: 100px;


  }
}

@media (min-width: 512px) {  

  .styleDiv{
    background-color: var(--opendemia-landing-grey);
    padding-bottom: 0ex;
    /* background-image: url("../Assets/LandingPage/gradholding.png"); */
    background-size: contain, 40%;
    background-position: center 200px;
    background-repeat: no-repeat;
    padding-bottom: 100px;


  }
}



@media (min-width: 768px) {  
  .titleFontSize {font-size:2.5em;}
  .checkboxMobileStyling {
    float:none;
    margin-right:0px;
  }

  .hero-bullet-text{
    text-align: center;
  }

  .styleDiv{
    background-color: var(--opendemia-landing-grey);

    padding-bottom: 0ex;
    /* background-image: url("../Assets/LandingPage/gradholding.png"); */
    background-size: 650px;
    background-position: left 35% top 225%;
    background-repeat: no-repeat;
    padding-bottom: 50px;

    

  }
  

}

/* Small devices (landscape phones, 544px and up) */
@media (min-width: 1px) {  
  .titleFontSizePricing {font-size:2rem; margin-top: .5rem;} 
  
}

@media (min-width: 768px) {  
  .titleFontSizePricing {font-size:3em;}

}

.text-color-darkmode{
  color:white;
}

.rec.rec-arrow:disabled {
  width: 20px !important;
  height: 20px !important;
  font-size: 1em !important;
  min-width: 20px  !important;
  line-height: 20px !important;
}
.rec.rec-arrow{
  width: 20px !important;
  height: 20px !important;
  font-size: 1em !important;
  min-width: 20px  !important;
  line-height: 20px !important;
}

.quoteCarouselItem {
  background: #fafafa;
  background-color: #fafafa;
  width: 90%;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
  text-align: center;
}

.quoteCarouselItemTeacher {
  background: #fafafa;
  background-color: #fafafa;
  width: 90%;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 296px;
  text-align: center;
}

.quoteCarouselItem h3 {
  color: #222;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-size: 24px;
  

}

.quoteCarouselItem h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  color: #787878;
  font-size: 14px;
}

.quoteCarouselItem p {
  font-weight: 100 !important;
  color: #222;
  font-family: sans-serif;
  max-height: 67px;
}


.rec.rec-arrow:hover {
  background-color: var(--opendemia-green) !important;


}

.rec.rec-arrow:focus {
  background-color: var(--opendemia-green) !important;


}

/*Landing 2*/

  .screenshotDiv {
  
    margin-top: -15rem;
    background: none;
    padding-bottom: 0;

}

/* .styleDiv {
  background-color: var(--opendemia-landing-grey);

  padding-bottom: 0ex;
  background-image: url("../Assets/LandingPage/gradholding.png");
  background-size: contain, 70%;
  background-position: center 150px;
  background-repeat: no-repeat;
 
} */

.topContainerStyle {

  padding-top: 7ex;

}


/*Patch for indicators{false} not working */
.carousel-indicators {
  display: none !important;
}
.quoteBlock .carousel-control-next-icon::after{
  content:"" !important;
}
.quoteBlock .carousel-control-prev-icon::after{
  content:"" !important;
}

#searchContainer {
  position: relative;
  z-index: 1;
  margin-bottom: 2ex;
  text-align: center;
}
.responsiveVidBlock {
  width: 50% !important;
  padding: 0;
  margin: auto;
  min-height: 200px;
  min-width: 250px;
}

.responsiveVidBlockFAQ {
 
  padding: 0;
  margin: auto;
  min-height: 200px;
  min-width: 250px;
}
.caroselCol {
  height: 10em;
  max-height: 10em;
  overflow: hidden;
  padding: 0.5em;
}

.blurContainer {
}

.subtitleText {
  color: #343a40;
  margin-top: 5ex;
  /* margin-bottom: 25ex; */
}

.search-bg-width {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.search-bg {
  /* Absolutely position it, but stretch it to all four corners, then put it just behind #search's z-index */
  position: absolute;

  /* background-image: url("/media/freelancer-desk.jpg"); */
  /* Pull the background 70px higher to the same place as #bg's */
  background-position: center 6934px;
  -webkit-filter: blur(8px);
  filter: blur(8px);
  background-image: url("../Assets/background.png");
  background-size: 2000px;
  border-radius: 100px;
  z-index: -1;
}

.search-bg-three {
  /* Absolutely position it, but stretch it to all four corners, then put it just behind #search's z-index */
  position: absolute;

  /* background-image: url("/media/freelancer-desk.jpg"); */
  /* Pull the background 70px higher to the same place as #bg's */
  background-position: center 6934px;
  -webkit-filter: blur(8px);
  filter: blur(8px);
  background-image: url("../Assets/backgroundChalk.png");
  background-size: 2000px;
  border-radius: 100px;
  z-index: -1;
}

.caroselQuoteCol {
  height: 16.5em;
  max-height: 16.5em;
}
.topHeaderText {
  /*background-color: linear-gradient(180deg, rgba(255,255,255,0) 65%, rgba(4,19,28,.8) 65%);*/
  /*display: inline;*/
  /* background-color: rgb(255,255,255,.5); */
  /* filter: blur(2px); */
  text-align: center;
}
.textContainerTop {
  /*filter: blur(2px);*/
  position: relative;
  z-index: 4;
  /* padding-left: 5%;
  padding-right: 5%; */
  display: inline-block;
}

.overlay1 {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
  filter: blur(1px);
  border-radius: 50px;
}

.readyToStartDiv {
  /* background-color: whitesmoke; */
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}
/*videoLanding embed-responsive-item*/
.videoLanding {
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  width: 100%;
  height: 100%;
  border: 0;
  right: auto;
}

#firstImage {
  background-image: url("../Assets/background.png");
  padding: 3%;
  background-size: 2000px;
  background-position: center 7067px;
  text-align: center;
}

#firstImageThird {
  background-image: url("../Assets/backgroundChalk.png");
  padding: 3%;
  text-align: center;

  background-size: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

#secondImage {
  background-image: url("../Assets/background.png");
  /* background-attachment: fixed;*/
  background-size: cover;
  background-position: center;
  background-position: 50% 40%;
  padding: 2%;
  min-height: 200px;
}
.logoPadding {
  padding: 1%;
}
.quoteBlock {
  padding: 15px;
  text-align: center;
}
.createaccountButton {
  border-radius: 20px;
  /* margin-bottom: 15ex; */
}

.createaccountButtonGreen {
  border-radius: 20px;
  /* margin-bottom: 15ex; */
  background-color: var(--opendemia-green) !important;
}

.opendemiaGreenColorBackground{
  background-color: var(--opendemia-green) !important;
  border: none !important;

}

.buttonBox2 {
  font-size: 1.5em !important;
  background-color: var(--opendemia-green) !important;
  border: none !important;
  /* margin-left: 3.5rem;
  margin-right: 3.5rem; */
  width:11em !important;
  margin-left:auto;
  margin-right:auto;
  
}

.landerTopCreateFreeAccount {
  font-size: 1.5em !important;
  background-color: var(--opendemia-green) !important;
  border: none !important;
  /* margin-left: 3.5rem;
  margin-right: 3.5rem; */
  width:13em !important;
  margin-right:auto;
  
}

.buttonBox3 {
  font-size: 1.5em !important;
  /* background-color: grey !important; */
  /* border: none !important; */
  /* margin-left: 3.5rem;
  margin-right: 3.5rem; */
  width:11em !important;
  margin-left:auto;
  margin-right:auto;
  color: white !important;
  border-color: white!important;
  background-color: var(--opendemia-landing-grey) !important
  
}

.backgroundColorOpendemiaGreen {
  background-color: var(--opendemia-green) !important;

}

.buttonBox3Green{

  font-size: 1.5em !important;
  /* background-color: grey !important; */
  /* border: none !important; */
  /* margin-left: 3.5rem;
  margin-right: 3.5rem; */
  width:15em !important;
  margin-left:auto;
  margin-right:auto;
  color: white !important;
  border-color: var(--opendemia-landing-grey) !important;
  background-color: var(--opendemia-green) !important;

}
#blurdiv {
  filter: blur(2px);
}
.firstInfoBlock {
  padding: 1% 1% 1% 1%;
  text-align: center;
}

.rowSpacingLanding {
  /* padding-left: 8em;
  padding-right: 8em; */
  padding-bottom: 7ex;
  padding-top: 3ex;
  /* padding-top: 2ex;
  padding-bottom: 2ex; */
}
.altColorLanding{
  background-color: whitesmoke;
  border-radius: 5px;
}

.textSpacingLanding {
  /* padding-left: 5em;
  padding-right: 5em; */
}

.descText {
  display: inline;
  z-index: 3;
}
.logo-images {
  max-width: 100%;
  height: auto;
  width: 25em;
}
.logoSides {
  margin-left: 20%;
  margin-right: 20%;
}
.caroselText {
  text-align: center;
}
.bottomBar {
  text-align: center;
  margin-bottom: 0.5%;
}

.caroselQuote {
  max-width: 960px;
}
/*ButtonAnimation*/
.createaccountButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.createaccountButton span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.createaccountButton:hover span {
  padding-right: 25px;
}

.createaccountButton:hover span:after {
  opacity: 1;
  right: 0;
}

/*Quote Themeing*/
blockquote {
  border-left: none;
}

.quote-badge {
  background-color: rgba(0, 0, 0, 0.2);
}
.quote-box-green {
  background-color: var(--opendemia-green);
  /* opacity: 0.9; */
}
.quote-box-teal {
  background-color: rgba(102, 153, 153, 0.9);
}
.quote-box-grey {
  background-color: rgba(3, 18, 25, 0.9);
}
.quote-box {
  margin: 0%;
  padding: 4%;
  overflow: hidden;
  border-radius: 17px;
  height: 100%;
  height: "-moz-available"; /* WebKit-based browsers will ignore this. */
  height: "-webkit-fill-available"; /* Mozilla-based browsers will ignore this. */
  height: "fit-content";
  /*background-color: rgba(3, 18, 25, 0.9);*/
  text-align: center;
  color: white;
  box-shadow: 2px 2px 2px 2px #e0e0e0;
}

.secondQuoteText {
  font-size: larger;
}

.quotation-mark {
  margin-top: -10px;
  font-weight: bold;
  font-size: 80px;
  color: white;
  font-family: "Times New Roman", Georgia, Serif;
}

.quote-text {
  font-size: 19px;
  margin-top: -65px;
}

/*Landing 1*/
.buttonBox {
  position: relative;
  top: -525px;
  width: 260px;
  background-color: var(--opendemia-green)!important;
  color: #fff !important;
  border: none;
}

.landingPageTopDiv {
  position: absolute;
  background-color: var(--main-bg-color);
  position: flex;
  width: 100%;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 232vh;
}

.topText {
  position: absolute;
  text-align: center;
  flex-direction: row;
  width: 700px;
  justify-content: space-evenly;
  font-size: 24px;
  top: 10px;
  overflow: hidden;
  color: white;
}

.topTextMain {
  position: absolute;
  text-align: center;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 32px;
  top: 60px;
  margin-top: 220px;
}

.topLeft {
  position: relative;
  left: -400px;
  text-align: center;
  flex-direction: row;
  width: 350px;
  justify-content: space-evenly;
  font-size: 14px;
  top: -300px;
}

.topMid {
  position: relative;
  top: -100px;
  text-align: center;
  flex-direction: row;
  width: 350px;
  justify-content: space-evenly;
  font-size: 14px;
  top: -380px;
}

.topRight {
  position: relative;
  top: -205px;
  left: 400px;
  text-align: center;
  flex-direction: row;
  width: 350px;
  justify-content: space-evenly;
  font-size: 14px;
  top: -485px;
}

.bottomLeft {
  position: relative;
  left: -200px;
  top: -288px;
  text-align: center;
  flex-direction: row;
  width: 350px;
  justify-content: space-evenly;
  font-size: 14px;
}

.bottomRight {
  position: relative;
  left: 200px;
  top: -435px;
  text-align: center;
  flex-direction: row;
  width: 350px;
  justify-content: space-evenly;
  font-size: 14px;
}

.vidTitle {
  position: relative;
  text-align: center;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 32px;
  top: -420px;
  margin-top: 225px;
}

.video {
  position: relative;
  text-align: center;
  flex-direction: row;
  justify-content: space-evenly;
  top: -445px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.bottomTitle {
  position: absolute;
  text-align: center;
  flex-direction: row;
  width: 700px;
  justify-content: space-evenly;
  font-size: 18px;
  top: 1250px;
  overflow: hidden;
  font-size: 24px;
}

.quotes {
  position: absolute;
  top: 1300px;
  text-align: center;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 14px;
}
.footerStyling{
  margin-bottom: 0 !important;
  padding-bottom: 1ex;
}

.temporaryComingSoon {
  position: relative;
  text-align: center;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 32px;
  top: -435px;
  margin-top: 225px;
  color: white;
}

.backImage {
  position: relative;
  text-align: center;
  top: -420px;
  filter: brightness(75%);
  left: -7px;
}
.backImageTwo {
  position: relative;
  text-align: center;
  top: -410px;
  height: 100px;
  filter: brightness(75%);
  left: -7px;
}

.slides {
  position: relative;
  text-align: center;
  top: -420px;
}

.beta {
  position: absolute;
  text-align: center;
  top: 1700px;
}

.card-newsletter.card {
  background-color: white !important;
  /* border: var(--opendemia-green) 1.6px solid !important; */
}

@media(min-width: 720px) {
  .py-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
}

.hr-color {
  border-top: 1px solid white
}

.marquee {
  height: 30px;
  width: 100%;
  background-color: #00B0F0;
  white-space: nowrap;
  overflow: hidden;
}

.marquee div {
  display: block;
  width: 250%;
  height: 30px;

  position: relative;
  overflow: hidden;

  animation: marquee 30s linear infinite;
}

@media (max-width: 767px) {
  .marquee div {
    width: 600%;
  }
}

.marquee span {
  float: left;
  width: 20%;
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}

.underlineOnHover:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}

.opendemiaLogoFont{
  font-family: "filson-soft", sans-serif;

}

.opendemiaBodyFont{
  font-family: 'Arimo', sans-serif;

}

h2 {
  font-family: "filson-soft", sans-serif;

}

h3 {
  font-family: "filson-soft", sans-serif;

}

